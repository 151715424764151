var storage;
if (typeof storage === "undefined") {
  var storage = (function () {
    var uid = new Date();
    var result;
    try {
      localStorage.setItem(uid, uid);
      result = localStorage.getItem(uid) == uid;
      localStorage.removeItem(uid);
      return result && localStorage;
    } catch (exception) {}
  })();
}

if (storage) {
  const utm_params = window.location.search.substring(1);

  const filtered_params = utm_params
    .split("&")
    .filter((param) => {
      return param.startsWith("utm_") || param.startsWith("sc");
    })
    .reduce((acc, param) => {
      const [key, value] = param.split("=");
      acc[key] = value;
      return acc;
    }, {});

  const existing = storage.getItem("merlin_cart_utm");

  if (existing) {
    const existing_params = JSON.parse(existing);
    const merged_params = { ...existing_params, ...filtered_params };
    storage.setItem("merlin_cart_utm", JSON.stringify(merged_params));
  } else {
    storage.setItem("merlin_cart_utm", JSON.stringify(filtered_params));
  }
}
